// Mixins for box alignment
// @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_box_alignment

/**
 * Generates defined modifiers for `justify-content`
 * on flex-box containers
 */
@mixin inline-axis-content-modifiers {
  &--justify-content {
    &-start {
      justify-content: flex-start;
    }
    &-end {
      justify-content: flex-end;
    }
    &-center {
      justify-content: center;
    }
    &-around {
      justify-content: space-around;
    }
    &-between {
      justify-content: space-between;
    }
  }
}

/**
 * Generates defined modifiers for `align-items`
 * on flex-box containers
 */
@mixin block-axis-item-modifiers() {
  &--align-items {
    &-start {
      align-items: flex-start;
    }
    &-end {
      align-items: flex-end;
    }
    &-center {
      align-items: center;
    }
    &-baseline {
      align-items: baseline;
    }
    &-stretch {
      align-items: stretch;
    }
  }
}

/**
 * Generates defined modifiers for `align-self`
 * on flex-box items
 */
@mixin block-axis-self-modifiers() {
  &--align-self {
    &-start {
      align-self: flex-start;
    }
    &-end {
      align-self: flex-end;
    }
    &-center {
      align-self: center;
    }
    &-baseline {
      align-self: baseline;
    }
  }
}
