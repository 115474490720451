@use '../../../core/theming/theming' as theming;
@use '../../../core/mixins/sass-utils.mixins' as utils;

@mixin -fmnts-label() {
  .fmnts-label {
    @include theming.typography-from-css-vars('fmnts-label');
    display: block;
    background-color: var(--fmnts-label--bg-color);
    color: var(--fmnts-label--color);
    padding: var(--fmnts-label--padding);
    text-transform: var(--fmnts-label--text-transform);
  }
}

@mixin base($theme) {
  @include -fmnts-label();

  .fmnts-label--filter-form {
    @include theming.typography-level-from-existing-vars(
      'fmnts-label',
      'fmnts-text-sm'
    );
    --fmnts-label--text-transform: uppercase;
    --fmnts-label--color: var(--fmnts-color--gray-darker);
  }

  .fmnts-label--auth-form {
    @include theming.typography-level-from-existing-vars(
      'fmnts-label',
      'fmnts-text-2xs'
    );
    --fmnts-label--color: var(--fmnts-color--gray-darker);
    --fmnts-label--font-weight: 300;
    --fmnts-label--line-height: 1.25;
  }
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
