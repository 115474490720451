@use '../mixins/shadow.mixins' as shadow;

/// Generates CSS variables for theme elevations.
@mixin css-vars-from-theme($theme) {
  $bottom-color: rgba(0, 0, 0, 0.2);
  $main-color: rgba(0, 0, 0, 0.14);
  $upper-color: rgba(0, 0, 0, 0.12);

  // Use px values for the shadows as these are not supposed to responsive

  // Level 0
  --fmnts-elevation--level-0: #{-create-shadow-level(
      $bottom: shadow.from-values($bottom-color, 0px, 0px, 0px, 0px),
      $main: shadow.from-values($main-color, 0px, 0px, 0px, 0px),
      $upper: shadow.from-values($upper-color, 0px, 0px, 0px, 0px)
    )};
  // Level 1
  --fmnts-elevation--level-1: #{-create-shadow-level(
      $bottom: shadow.from-values($bottom-color, 0px, 2px, 1px, -1px),
      $main: shadow.from-values($main-color, 0px, 1px, 1px, 0px),
      $upper: shadow.from-values($upper-color, 0px, 1px, 3px, 0px)
    )};
  // Level 2
  --fmnts-elevation--level-2: #{-create-shadow-level(
      $bottom: shadow.from-values($bottom-color, 0px, 3px, 3px, -2px),
      $main: shadow.from-values($main-color, 0px, 3px, 4px, 0px),
      $upper: shadow.from-values($upper-color, 0px, 1px, 8px, 0px)
    )};
  // Level 3
  --fmnts-elevation--level-3: #{-create-shadow-level(
      $bottom: shadow.from-values($bottom-color, 0px, 3px, 5px, -1px),
      $main: shadow.from-values($main-color, 0px, 6px, 10px, 0px),
      $upper: shadow.from-values($upper-color, 0px, 1px, 18px, 0px)
    )};
  // Level 4
  --fmnts-elevation--level-4: #{-create-shadow-level(
      $bottom: shadow.from-values($bottom-color, 0px, 5px, 5px, -3px),
      $main: shadow.from-values($main-color, 0px, 8px, 10px, 1px),
      $upper: shadow.from-values($upper-color, 0px, 3px, 14px, 2px)
    )};
  // Level 5
  --fmnts-elevation--level-5: #{-create-shadow-level(
      $bottom: shadow.from-values($bottom-color, 0px, 7px, 8px, -4px),
      $main: shadow.from-values($main-color, 0px, 12px, 17px, 2px),
      $upper: shadow.from-values($upper-color, 0px, 5px, 22px, 4px)
    )};
}

@function -create-shadow-level($bottom, $main, $upper) {
  @return $bottom, $main, $upper;
}
