@use '../../core/theming/theming';
@use '../../core/mixins/breakpoints.mixins' as bp;

@mixin base($theme) {
  fmnts-navigation-bar {
    --fmnts-navigation-bar-logo--display: none;
    --fmnts-navigation-bar-logo--visibility: hidden;
    --fmnts-navigation-bar-breadcrumbs--display: inline-flex;
    --fmnts-navigation-bar-sidebar-toggle--display: inline-flex;

    // For large screens:
    // - show the full Logo
    // - hide Breadcrumbs & Sidebar-toggle
    @include bp.up($theme, 'lg') {
      --fmnts-navigation-bar-logo--display: inline-flex;
      --fmnts-navigation-bar-logo--visibility: visible;
      --fmnts-navigation-bar-breadcrumbs--display: none;
      --fmnts-navigation-bar-sidebar-toggle--display: none;
    }
  }
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
