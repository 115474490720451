@use '../../core/theming/theming';

@mixin base($theme) {
}

@mixin color($theme) {
}

@mixin typography($theme) {
  .fmnts-bar-chart {
    &-value {
      @include theming.typography-level-from-existing-vars(
        'fmnts-bar-chart-value',
        'fmnts-text-xl'
      );
    }
  }
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
