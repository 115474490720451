@mixin error-page($theme) {
  .error-page {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 1rem;
  }
}
