@use 'sass:color';
@use 'sass:map';
@use '../core/theming/theming';
@use './ci-colors' as ci;

// Dark colored text
$dark-primary-text: #000000 !default;
// Light color text
$light-primary-text: #ffffff !default;

$palette-primary: theming.define-from-palette(
  $default: map.get(ci.$ci-colors, 'primary'),
  $dark: map.get(ci.$ci-colors, 'pantone_301'),
  $darker: color.adjust(map.get(ci.$ci-colors, 'pantone_301'), $lightness: -3%),
  $darkest: color.adjust(map.get(ci.$ci-colors, 'pantone_301'), $lightness: -5%),
  $light: map.get(ci.$ci-colors, 'primary-tint-50'),
  $lighter: map.get(ci.$ci-colors, 'primary-tint-25'),
  $lightest:
    color.change(
      map.get(ci.$ci-colors, 'primary'),
      $saturation: 66%,
      $lightness: 96%
    ),
  $contrast: (
    'default': $light-primary-text,
    'dark': $light-primary-text,
    'darker': $light-primary-text,
    'darkest': $light-primary-text,
    'light': $dark-primary-text,
    'lighter': $dark-primary-text,
    'lightest': $dark-primary-text,
  ),
) !default;

$palette-black: theming.define-from-palette(
  $dark: #000000,
  $default: #000000,
  $light: #222222,
  $lighter: #292b2c,
  $lightest: #464a4c,
  $contrast: (
    'default': $light-primary-text,
    'dark': $light-primary-text,
    // 'darker': $light-primary-text,
    // 'darkest': $light-primary-text,
    'light': $light-primary-text,
    'lighter': $light-primary-text,
    'lightest': $light-primary-text,
  ),
) !default;

$palette-white: theming.define-from-palette(
  $light: #ffffff,
  $default: #ffffff,
  $dark: #f7f7f9,
  $darker: #eceeef,
  $darkest: #e5e5e5,
  $contrast: (
    'default': $dark-primary-text,
    'dark': $dark-primary-text,
    'darker': $dark-primary-text,
    'darkest': $dark-primary-text,
    // 'lighter': $dark-primary-text,
    // 'lightest': $dark-primary-text,
    'light': $dark-primary-text,
  ),
) !default;

$palette-gray: theming.define-from-palette(
  $lightest: #f8fafc,
  $lighter: #f1f5f8,
  $light: #dae1e7,
  $default: #b8c2cc,
  $dark: #8795a1,
  $darker: #606f7b,
  $darkest: #3d4852,
  $contrast: (
    'default': $dark-primary-text,
    'dark': $dark-primary-text,
    'darker': $light-primary-text,
    'darkest': $light-primary-text,
    'light': $dark-primary-text,
    'lighter': $dark-primary-text,
    'lightest': $dark-primary-text,
  ),
);

/**
 ** Contextual color palettes
 **/

$-danger-default: #e3342f;
$palette-danger: theming.define-from-palette(
  $lightest: color.adjust($-danger-default, $lightness: 45%),
  $lighter: color.adjust($-danger-default, $lightness: 25%),
  $light: color.adjust($-danger-default, $lightness: 10%),
  $default: $-danger-default,
  $dark: color.adjust($-danger-default, $lightness: -10%),
  // $darker: ,
  // $darkest: ,
  $contrast:
    (
      'default': $light-primary-text,
      'dark': $light-primary-text,
      'darker': $light-primary-text,
      'darkest': $light-primary-text,
      'light': $dark-primary-text,
      'lighter': $dark-primary-text,
      'lightest': $dark-primary-text,
    ),
);

$-success-default: #38c172;
$palette-success: theming.define-from-palette(
  $lightest: color.adjust($-success-default, $lightness: 48%),
  $lighter: color.adjust($-success-default, $lightness: 25%),
  $light: color.adjust($-success-default, $lightness: 10%),
  $default: $-success-default,
  $dark: color.adjust($-success-default, $lightness: -10%),
  // $darker: ,
  // $darkest: ,
  $contrast:
    (
      'default': $light-primary-text,
      'dark': $light-primary-text,
      'darker': $light-primary-text,
      'darkest': $light-primary-text,
      'light': $dark-primary-text,
      'lighter': $dark-primary-text,
      'lightest': $dark-primary-text,
    ),
);

$-warn-default: map.get(ci.$ci-colors, 'golden-grass');
$palette-warn: theming.define-from-palette(
  $lightest: color.adjust($-warn-default, $lightness: 43%),
  $lighter: color.adjust($-warn-default, $lightness: 25%),
  $light: color.adjust($-warn-default, $lightness: 10%),
  $default: $-warn-default,
  $dark: color.adjust($-warn-default, $lightness: -10%),
  // $darker: ,
  // $darkest: ,
  $contrast:
    (
      'default': $dark-primary-text,
      'dark': $light-primary-text,
      'darker': $light-primary-text,
      'darkest': $light-primary-text,
      'light': $dark-primary-text,
      'lighter': $dark-primary-text,
      'lightest': $dark-primary-text,
    ),
);
