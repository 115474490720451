/// Generates CSS variables for theme animations.
@mixin css-vars-from-theme($theme) {
  @include -apply-common-duration-vars($theme);
  @include -apply-common-easing-vars($theme);
}

/// CSS variables for durations.
@mixin -apply-common-duration-vars($theme) {
  // Short variants
  --fmnts-duration--short-1: 50ms;
  --fmnts-duration--short-2: 100ms;
  --fmnts-duration--short-3: 150ms;
  --fmnts-duration--short-4: 200ms;
  // Medium variants
  --fmnts-duration--medium-1: 250ms;
  --fmnts-duration--medium-2: 300ms;
  --fmnts-duration--medium-3: 350ms;
  --fmnts-duration--medium-4: 400ms;
  // Long variants
  --fmnts-duration--long-1: 450ms;
  --fmnts-duration--long-2: 500ms;
  --fmnts-duration--long-3: 550ms;
  --fmnts-duration--long-4: 600ms;
  // Extra long variants
  --fmnts-duration--extra-long-1: 700ms;
  --fmnts-duration--extra-long-2: 800ms;
  --fmnts-duration--extra-long-3: 900ms;
  --fmnts-duration--extra-long-4: 1000ms;
}

/// CSS variables for easing sets.
@mixin -apply-common-easing-vars($theme) {
  // Standard easing set
  --fmnts-ease--standard: cubic-bezier(0.4, 0, 0.2, 1);
  --fmnts-ease--standard-decelerate: cubic-bezier(0, 0, 0.2, 1);
  --fmnts-ease--standard-accelerate: cubic-bezier(0.4, 0, 1, 1);
  // Emphasized easing set
  --fmnts-ease--emphasized: cubic-bezier(0.4, 0, 0.6, 1);
  --fmnts-ease--emphasized-decelerate: cubic-bezier(0.15, 0.7, 0.3, 1);
  --fmnts-ease--emphasized-accelerate: cubic-bezier(0.3, 0, 0.8, 0.15);
}
