@use '@fmnts/components/core/mixins/breakpoints.mixins' as bp;

@mixin theme($theme) {
  app-toast {
    width: 100%;

    @include bp.up($theme, 'md') {
      max-width: 400px;
    }
  }
}
