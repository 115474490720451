@use '../../../core/mixins/breakpoints.mixins' as bp;
@use '../../../core/mixins/layout.mixins' as layout;

@mixin base($theme) {
  .fmnts-layout {
    display: grid;
    outline: none;
    gap: var(--fmnts-layout--gap, 0);
  }

  .fmnts-layout--page {
    height: 100%;
    grid-template-areas:
      'header      header  header'
      'panel-start content panel-end'
      'footer      footer  footer';

    grid-template-columns:
      var(--fmnts-layout-slot-panel-start--width, min-content)
      minmax(0, 1fr)
      var(--fmnts-layout-slot-panel-end--width, min-content);

    grid-template-rows:
      var(--fmnts-layout-slot-header--height, min-content)
      auto
      var(--fmnts-layout-slot-footer--height, min-content);
  }

  .fmnts-layout--columns {
    height: 100%;
    grid-template-areas:
      'panel-start header  panel-end'
      'panel-start content panel-end'
      'panel-start footer  panel-end';

    grid-template-columns:
      var(--fmnts-layout-slot-panel-start--width, min-content)
      minmax(0, 1fr)
      var(--fmnts-layout-slot-panel-end--width, min-content);

    grid-template-rows:
      var(--fmnts-layout-slot-header--height, min-content)
      auto
      var(--fmnts-layout-slot-footer--height, min-content);
  }
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
