@use './_css-custom-props.mixins' as css-props;

/*
 * Creates the padding for a box.
 */
@mixin padding-box(
  // General spacing
  $space: 0,
  // Spacing on the inline axis
  $inline: $space,
  // Spacing on the block axis
  $block: $space,
  // Spacing at the start of the inline axis
  $inline-start: $inline,
  // Spacing at the end of the inline axis
  $inline-end: $inline,
  // Spacing at the start of the block axis
  $block-start: $block,
  // Spacing at the end of the block axis
  $block-end: $block
) {
  padding-left: #{$inline-start};
  padding-right: #{$inline-end};
  padding-top: #{$block-start};
  padding-bottom: #{$block-end};
}

/**
 * Creates the margin for a box.
 */
@mixin margin-box(
  // General spacing
  $space: 0,
  // Spacing on the inline axis
  $inline: $space,
  // Spacing on the block axis
  $block: $space,
  // Spacing at the start of the inline axis
  $inline-start: $inline,
  // Spacing at the end of the inline axis
  $inline-end: $inline,
  // Spacing at the start of the block axis
  $block-start: $block,
  // Spacing at the end of the block axis
  $block-end: $block
) {
  margin-left: #{$inline-start};
  margin-right: #{$inline-end};
  margin-top: #{$block-start};
  margin-bottom: #{$block-end};
}

@mixin padding-from-vars(
  $element,
  // General spacing
  $space: 0,
  // Spacing on the inline axis
  $inline: $space,
  // Spacing on the block axis
  $block: $space,
  // Spacing at the start of the inline axis
  $inline-start: $inline,
  // Spacing at the end of the inline axis
  $inline-end: $inline,
  // Spacing at the start of the block axis
  $block-start: $block,
  // Spacing at the end of the block axis
  $block-end: $block
) {
  @include padding-box(
    $inline-start: #{css-props.value-from-vars(
        css-props.combine-by-prefix($element, $props: padding-start padding-x),
        $inline-start
      )},
    $inline-end: #{css-props.value-from-vars(
        css-props.combine-by-prefix($element, $props: padding-end padding-x),
        $inline-end
      )},
    $block-start: #{css-props.value-from-vars(
        css-props.combine-by-prefix($element, $props: padding-top padding-y),
        $block-start
      )},
    $block-end: #{css-props.value-from-vars(
        css-props.combine-by-prefix($element, $props: padding-bottom padding-y),
        $block-end
      )}
  );
}
