// This file is directly added to our app via angular.json
// The reason for this is, so that these styles are available
// BEFORE our app has loaded, as this is a placeholder that
// is displayed, while our page is loading
@import '@fmnts/components/corporate-identity/src/lib/rocket.component.scss';

@keyframes initial-page-load-spinner-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#initial-page-load-spinner {
  // Fill container
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // Center content
  display: flex;
  justify-content: center;
  align-items: center;

  // Show animation
  animation: initial-page-load-spinner-animation 4s linear 0s;

  .rocket {
    // Let logo grow between 33 and 50 percent or screen height
    min-height: 33vh;
    max-height: 50vh;
    min-width: 33vw;
    max-width: 50vw;
    height: 100%;
    width: 100%;
  }
}
