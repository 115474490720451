@use 'sass:map';

@mixin input-style-from-css-vars() {
  border: var(--fmnts-input--border-width) var(--fmnts-input--border-style)
    var(--fmnts-input--border-color);
  border-radius: var(--fmnts-input--border-radius);
  background-color: var(--fmnts-input--bg-color);
  color: var(--fmnts-input--color);
  font-size: var(--fmnts-input--font-size);
  font-weight: var(--fmnts-input--font-weight);
  line-height: var(--fmnts-input--line-height);
  padding: {
    top: var(--fmnts-input--padding-top);
    right: var(--fmnts-input--padding-end);
    bottom: var(--fmnts-input--padding-bottom);
    left: var(--fmnts-input--padding-start);
  }
  transition: var(--fmnts-input--transition);
}

@mixin input-focus-style-from-css-vars() {
  border-color: var(--fmnts-input--focus--border-color);
}

@mixin input-invalid-style-from-css-vars() {
  border-color: var(--fmnts-input--invalid--border-color);
}

@mixin input-disabled-style-from-css-vars() {
  background-color: var(--fmnts-input--disabled--bg-color);
  border-color: var(--fmnts-input--disabled--border-color);
}

@mixin placeholder-style-from-css-vars() {
  color: var(--fmnts-input-placeholder--color);
}
