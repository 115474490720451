@use '../../../core/mixins/breakpoints.mixins' as bp;
@use '../../../core/mixins/flex.mixins' as flex;
@use '../../../core/mixins/layout.mixins' as layout;

@mixin -item-gap($wrapped) {
  $gap: var(--fmnts-flex--gap);

  &.fmnts-flex--column {
    @include flex.item-gap(
      $gap: $gap,
      $direction: 'column',
      $wrapped: $wrapped
    );
  }
  &.fmnts-flex--column-reverse {
    @include flex.item-gap(
      $gap: $gap,
      $direction: 'column',
      $reversed: true,
      $wrapped: $wrapped
    );
  }
  &.fmnts-flex--row {
    @include flex.item-gap($gap: $gap, $direction: 'row', $wrapped: $wrapped);
  }
  &.fmnts-flex--row-reverse {
    @include flex.item-gap(
      $gap: $gap,
      $direction: 'row',
      $reversed: true,
      $wrapped: $wrapped
    );
  }
}

@mixin base($theme) {
  .fmnts-flex {
    display: flex;
  }
  @include bp.apply-with-modifiers($theme, '.fmnts-flex') {
    @include flex.generate-container-modifieres();
  }

  .fmnts-flex--gap {
    &.fmnts-flex--nowrap {
      @include -item-gap($wrapped: false);
    }

    &.fmnts-flex--wrap,
    &.fmnts-flex--wrap-reverse {
      @include -item-gap($wrapped: true);
    }
  }

  .fmnts-flex--grow {
    &-hug {
      flex-grow: 0;
    }
    &-fill {
      flex-grow: 1;

      &.fmnts-flex--row,
      &.fmnts-flex--row-reverse {
        height: 100%;
      }
      &.fmnts-flex--column,
      &.fmnts-flex--column-reverse {
        width: 100%;
      }
    }
  }

  /** Provides a container with overflowing content */
  .fmnts-flex--reel {
    scroll-snap-type: var(--fmnts-flex--scroll-snap-axis) mandatory;

    > * {
      scroll-snap-align: start;
    }

    &.fmnts-flex--column,
    &.fmnts-flex--column-reverse {
      --fmnts-flex--scroll-snap-axis: y;
      overflow-y: auto;
    }
    &.fmnts-flex--row,
    &.fmnts-flex--row-reverse {
      --fmnts-flex--scroll-snap-axis: x;
      overflow-x: auto;
    }

    &.fmnts-flex--gap {
      &.fmnts-flex--column,
      &.fmnts-flex--column-reverse {
        scroll-padding-top: var(--fmnts-flex--reel-gap, var(--fmnts-flex--gap));
      }
      &.fmnts-flex--row,
      &.fmnts-flex--row-reverse {
        scroll-padding-left: var(
          --fmnts-flex--reel-gap,
          var(--fmnts-flex--gap)
        );
      }
    }
  }
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
