@mixin page-wrapper($theme) {
  .page-wrapper {
    &--blue {
      background: var(--fmnts-color--primary);
      color: var(--fmnts-color--primary-contrast);
    }

    &--column {
      display: flex;
      flex-direction: column;
    }

    &--center {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &--fullscreen {
      width: 100vw;
      height: 100vh;

      // Variant that is at least fullscreen, but can be bigger if needed
      &-min {
        // Use `%` instead of `vw`, because otherwise a horizontal scrollbar
        // will be shown if the content exceeds the available height
        min-width: 100%;
        // This will take up the full height
        min-height: 100vh;
      }
    }
  }
}
