@use 'sass:map';
@use '../../../core/mixins/breakpoints.mixins' as bp;
@use '../../../core/theming/variables' as vars;

@mixin column-styles($fractions) {
  // Generate column span classes
  $fractions: map.get(vars.$grid, 'fractions');
  &--span-full {
    --fmnts-cols-column--column-span: var(--fmnts-cols--columns);
  }

  @each $fraction in $fractions {
    @for $i from 1 to $fraction {
      &--span-#{$i}\/#{$fraction} {
        --fmnts-cols-column--fraction: #{$fraction};
        --fmnts-cols-column--column-span: calc(
          #{$i} *
            var(--fmnts-cols--columns) /
            var(--fmnts-cols-column--fraction)
        );
      }
    }
  }

  // Generate column start classes
  $column-count: map.get(vars.$grid, 'column-count');
  @for $i from 1 through $column-count {
    &--start-#{$i} {
      --fmnts-cols-column--column-start: #{$i};
      grid-column-start: #{$i};
    }
  }
}

@mixin base($theme) {
  .fmnts-cols {
    display: grid;
    grid-template-rows: repeat(var(--fmnts-cols--rows, 1), 1fr);
    grid-template-columns: repeat(
      var(--fmnts-cols--columns, map.get(vars.$grid, 'column-count')),
      1fr
    );
    gap: var(--fmnts-cols--gap, 0);
  }

  .fmnts-col {
    grid-column-start: var(--fmnts-cols-column--column-start);
    grid-column-end: var(
      --fmnts-cols-column--column-end,
      span var(--fmnts-cols-column--column-span)
    );
    grid-row-start: var(--fmnts-cols-column--row-start);
    grid-row-end: var(
      --fmnts-cols-column--row-end,
      span var(--fmnts-cols-column--row-span)
    );
  }
  @include bp.apply-with-modifiers($theme, '.fmnts-col') {
    @include column-styles(vars.$grid);
  }
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
