@use '../../../core/mixins/input.mixins' as input-field;
@use '../../../core/theming/theming' as theming;

@mixin -fmnts-prefix-suffix() {
  .fmnts-text-input__prefix-item,
  .fmnts-text-input__suffix-item {
    overflow: hidden;
  }

  .fmnts-text-input__prefix-item,
  .fmnts-text-input__suffix-item {
    &--align-block-baseline {
      align-self: baseline;
      padding-top: var(--fmnts-text-input--padding-top);
      padding-bottom: var(--fmnts-text-input--padding-bottom);
      margin-bottom: auto;
    }
    &--align-block-center {
      align-self: center;
    }
  }

  .fmnts-text-input__prefix-item {
    border-top-left-radius: var(--fmnts-text-input--border-radius);
    border-bottom-left-radius: var(--fmnts-text-input--border-radius);
  }

  .fmnts-text-input__suffix-item {
    border-top-right-radius: var(--fmnts-text-input--border-radius);
    border-bottom-right-radius: var(--fmnts-text-input--border-radius);
  }

  fa-icon {
    &.fmnts-text-input__prefix-item,
    &.fmnts-text-input__suffix-item {
      box-sizing: content-box;
      color: var(--fmnts-text-input-icon--color);

      padding-left: var(--fmnts-text-input-icon--padding-x);
      padding-right: var(--fmnts-text-input-icon--padding-x);
    }

    &.fmnts-text-input__prefix-item {
      margin-right: var(--fmnts-text-input-icon--margin-x);
    }
    &.fmnts-text-input__suffix-item {
      margin-left: var(--fmnts-text-input-icon--margin-x);
    }
  }

  .fmnts-text-input__prefix,
  .fmnts-text-input__suffix {
    > .fmnts-icon-button {
      margin-left: var(--fmnts-text-input-icon-button--space-x);
      margin-right: var(--fmnts-text-input-icon-button--space-x);
      align-self: center;
    }
  }
}

@mixin base($theme) {
  @include -fmnts-prefix-suffix();
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
