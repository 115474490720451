// ******************************
// Global styles for app
// ******************************
@use '@fmnts/components/core/theming/theming';
@use '@fmnts/components/corporate-identity/formunauts-design-system.fonts' as
  fonts;
@use '@fmnts/components/corporate-identity/formunauts-design-system.tokens' as
  tokens;
@use './styles/app.theme.scss' as app-theme;
@use './styles/elements' as elements;

// Import shared modules not yet in @fmnts/components
@use './app/shared/messaging/styles/messaging' as msg;

@include fonts.figtree();
@include fonts.chillax();

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import '@fmnts/components/core/_core';

  @include tokens.emit();
  @include theming.typography-native-html();
  @include theming.native-html-links();

  // Overwrite default list-styling
  // this is needed for html rendering in forms
  // @see https://tailwindcss.com/docs/preflight#lists-are-unstyled
  ol li {
    @apply list-decimal list-inside;
  }

  ul li {
    @apply list-disc list-inside;
  }
}

@include app-theme.apply();
@include elements.apply-all(app-theme.$theme);

// Apply styles from shared modules not yet in @fmnts/components
@include msg.apply-all(app-theme.$theme);
