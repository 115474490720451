@use 'sass:map';
@use '../../formunauts.palette' as fmnts-palette;

.rocket {
  // Normally this would go inside a `*.theme.scss` file, but atm
  // this is not really supposed to be themed as these are the
  // formunauts colors.
  $rocket-color-primary: map.get(fmnts-palette.$palette-primary, 'default');
  $rocket-color-white: map.get(fmnts-palette.$palette-white, 'default');

  --fmnts-rocket--thrust-speed: 0.5;

  flex: 1 1 1em;
  box-sizing: content-box;
  display: inline-block;
  line-height: 1;
  height: 1em; // Uses fonts-size to style height
  width: 1em; // Uses fonts-size to style height
  // Variables
  color: var(--fmnts-rocket--color, inherit);
  font-size: var(--fmnts-rocket--size, inherit);

  svg {
    box-sizing: content-box;
    display: inline-block;
    line-height: 1;
    height: 1em;
    vertical-align: baseline;
    fill-rule: evenodd;
    clip-rule: evenodd;
    stroke-linejoin: round;
    stroke-miterlimit: 2;
    fill: currentColor;
    width: 100%;
  }

  // Color variants
  &--color {
    &-primary {
      --fmnts-rocket--color: #{$rocket-color-primary};
    }
    &-white {
      --fmnts-rocket--color: #{$rocket-color-white};
    }
  }

  // Style size variants
  &--size {
    &-xs {
      --fmnts-rocket--size: 48px;
    }
    &-sm {
      --fmnts-rocket--size: 64px;
    }
    &-md {
      --fmnts-rocket--size: 96px;
    }
    &-lg {
      --fmnts-rocket--size: 160px;
    }
  }

  &--fill-container {
    flex: 1 1 0;
    svg {
      height: 100%;
      width: 100%;
    }
  }

  &__thrust {
    transform: rotate(-45deg);

    animation-name: rocket-thrusts-loading;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    --fmnts-rocket--thrust-width: 295px;

    &:nth-child(1) {
      animation-duration: calc(1s * var(--fmnts-rocket--thrust-speed));
    }
    &:nth-child(2) {
      --fmnts-rocket--thrust-width: 400px;
      animation-duration: calc(0.7s * var(--fmnts-rocket--thrust-speed));
    }
    &:nth-child(3) {
      animation-duration: calc(0.8s * var(--fmnts-rocket--thrust-speed));
    }

    &--stopped {
      animation-play-state: paused;
    }
  }
}

@keyframes rocket-thrusts-loading {
  0% {
    width: 0px;
  }
  100% {
    width: var(--fmnts-rocket--thrust-width, 295px);
  }
}
