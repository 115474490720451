@use 'sass:map';
@use 'sass:string';

/**
 * Returns the breakpoints from the theme.
 * Can be used directly in `@each` loops.
 */
@function get-all($theme) {
  @return map.get($theme, 'breakpoints');
}

/**
 * Returns a list of all breakpoint names in the theme.
 */
@function get-names($theme) {
  @return map.keys(get-all($theme));
}

/**
 * Returns the value of the given breakpoint.
 */
@function get-bp($theme, $breakpoint) {
  @return map.get(get-all($theme), $breakpoint);
}

/**
 * Applies the styles for media (e.g. screens)
 * with a minimum width of the defined value
 * of the given breakpoint.
 */
@mixin up($theme, $breakpoint) {
  @media (min-width: get-bp($theme, $breakpoint)) {
    @content;
  }
}

/**
 * Applies the styles for media (e.g. screens)
 * with a minimum width of the defined value
 * of the given breakpoint.
 *
 * **NOTE**: Prefer using up() instead of down().
 */
@mixin down($theme, $breakpoint) {
  @media (max-width: (get-bp($theme, $breakpoint) - 1px)) {
    @content;
  }
}

@mixin between($theme, $from, $to) {
  $bp-min: get-bp($theme, $from);
  $bp-max: get-bp($theme, $to) - 1px;

  @media (min-width: $bp-min) and (max-width: $bp-max) {
    @content;
  }
}

/**
 * Applies the given styling for any
 * Applies the given styling for a given `$postfix` as a class.
 *
 * @example
 * apply-with-modifiers($theme, 'test') { color: green; };
 *
 * .test { color: green; }
 * .sm:test { color: green; }
 * .md:test { color: green; }
 * ...
 */
@mixin apply-with-modifiers($theme, $postfix) {
  // Check parameter
  @if string.index($postfix, ' ') != null or string.index($postfix, ',') {
    @error '$postfix must be a single string that contains a compound selector. This means it may not contain combinators (including spaces) or commas. Received #{$postfix}.';
  }
  @if string.index($postfix, '.') != 1 {
    @error '$postfix #{$postfix} must be a classname';
  }

  // Make sure that the passed postfix can be used
  // as a class name
  $_postfix: string.slice($postfix, 2);

  // First, apply the styles directly.
  .#{$_postfix} {
    @content;
  }

  // Then create modifiers for each breakpoint
  @include -modifiers($theme, $_postfix) {
    @content;
  }
}

/**
 * Creates breakpoint based modifiers for a style rule.
 * Should be used inside a class.
 */
@mixin -modifiers($theme, $postfix: '') {
  @each $bp in get-names($theme) {
    $bp-selector: '.#{$bp}\\:#{$postfix}';
    #{$bp-selector} {
      @include up($theme, $bp) {
        @content;
      }
    }
  }
}
