// **************************************
// These mixins create the font faces for
// the Formunauts Design System fonts.
// **************************************

@mixin figtree() {
  @font-face {
    font-family: 'Figtree';
    font-style: italic;
    font-weight: 300 900;
    font-display: swap;
    src:
      url('/assets/fonts/figtree/Figtree-Italic-Variable.woff2') format('woff2'),
      url('/assets/fonts/figtree/Figtree-Italic-Variable.ttf')
        format('truetype');
  }

  @font-face {
    font-family: 'Figtree';
    font-style: normal;
    font-weight: 300 900;
    font-display: swap;
    src:
      url('/assets/fonts/figtree/Figtree-Variable.woff2') format('woff2'),
      url('/assets/fonts/figtree/Figtree-Variable.ttf') format('truetype');
  }
}

@mixin chillax() {
  @font-face {
    font-family: 'Chillax';
    font-style: normal;
    font-weight: 200 700;
    font-display: swap;
    src:
      url('/assets/fonts/chillax/Chillax-Variable.woff2') format('woff2'),
      url('/assets/fonts/chillax/Chillax-Variable.woff') format('woff'),
      url('/assets/fonts/chillax/Chillax-Variable.ttf') format('truetype');
  }
}
