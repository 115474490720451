@mixin base($theme) {
}

@mixin color($theme) {
  fmnts-drawer-container {
    --fmnts-drawer--color: var(--fmnts-color--white-contrast);
    --fmnts-drawer--bg-color: var(--fmnts-color--white);
    --fmnts-drawer--scroll-color: var(--fmnts-color--primary);
    --fmtns-drawer--border-color: var(--fmnts-color--white-darker);
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
