@use 'sass:map';
@forward './animation.theming' as animation-*;
@forward './border.theming' as border-*;
@forward './color.theming' as color-*;
@forward './elevation.theming' as elevation-*;
@forward './native-html.theming' as native-html-*;
@forward './typography.theming' as typography-*;

// Helper that cleans a palettes missing hues my prefilling them
@function -make-clean-palette(
  $default,
  $dark,
  $darker,
  $darkest,
  $light,
  $lighter,
  $lightest
) {
  $result: (
    default: $default,
    dark: $dark,
    light: $light,
  );

  @if $darker == false {
    $darker: $dark;
  }
  @if $darkest == false {
    $darkest: $darker;
  }

  @if $lighter == false {
    $lighter: $light;
  }
  @if $lightest == false {
    $lightest: $lighter;
  }

  @return map.merge(
    $result,
    (
      darkest: $darkest,
      darker: $darker,
      lighter: $lighter,
      lightest: $lightest,
    )
  );
}

@function define-from-palette(
  $default,
  $dark,
  $darker: false,
  $darkest: false,
  $light,
  $lighter: false,
  $lightest: false,
  /**
   * Map that holds the contrast colors.
   */ $contrast: ()
) {
  $result: -make-clean-palette(
    $default: $default,
    $dark: $dark,
    $darker: $darker,
    $darkest: $darkest,
    $light: $light,
    $lighter: $lighter,
    $lightest: $lightest,
  );

  // Make clean contrast map
  $contrast-darker: false;
  @if map.has-key($contrast, 'darker') {
    $contrast-darker: map.get($contrast, 'darker');
  }
  $contrast-darkest: false;
  @if map.has-key($contrast, 'darkest') {
    $contrast-darkest: map.get($contrast, 'darkest');
  }
  $contrast-lighter: false;
  @if map.has-key($contrast, 'lighter') {
    $contrast-lighter: map.get($contrast, 'lighter');
  }
  $contrast-lightest: false;
  @if map.has-key($contrast, 'lightest') {
    $contrast-lightest: map.get($contrast, 'lightest');
  }
  $contrast-map: -make-clean-palette(
    $default: map.get($contrast, 'default'),
    $dark: map.get($contrast, 'dark'),
    $darker: $contrast-darker,
    $darkest: $contrast-darkest,
    $light: map.get($contrast, 'light'),
    $lighter: $contrast-lighter,
    $lightest: $contrast-lightest,
  );

  // Merge the result with the contrast map, so that everything
  // is in a single map
  @return map.merge(
    $result,
    (
      'contrast-default': map.get($contrast-map, 'default'),
      'contrast-dark': map.get($contrast-map, 'dark'),
      'contrast-darker': map.get($contrast-map, 'darker'),
      'contrast-darkest': map.get($contrast-map, 'darkest'),
      'contrast-light': map.get($contrast-map, 'light'),
      'contrast-lighter': map.get($contrast-map, 'lighter'),
      'contrast-lightest': map.get($contrast-map, 'lightest'),
    )
  );
}

/**
 * returns the variable map for the given component
 */
@function get-component-vars($theme, $component) {
  $all-component-vars: map.get($theme, 'components');

  @if map.has-key($all-component-vars, $component) == false {
    @warn '[theming]: unknown "#{$component}"';
  }

  @return map.get($all-component-vars, $component);
}

/**
 * Creates a theme from variables
 */
@function make-theme($colors, $breakpoints, $components, $typography) {
  @return (
    'colors': $colors,
    'breakpoints': $breakpoints,
    'components': $components,
    'typography': $typography
  );
}
