@use '../../../core/theming/theming' as theming;

@mixin -form-error-vars() {
  .fmnts-form-error {
    --fmnts-form-error--color: var(--fmnts-color--danger);
  }
}

@mixin -form-error-styles() {
  .fmnts-form-error {
    display: block;
    color: var(--fmnts-form-error--color);
    @include theming.typography-from-css-vars('fmnts-form-error');
    // Usually set within fmnts-form-field
    padding: var(--fmnts-form-error--padding);
  }
}

@mixin base($theme) {
  @include -form-error-vars();
  @include -form-error-styles();
}

@mixin color($theme) {
}

@mixin typography($theme) {
  .fmnts-form-error {
    @include theming.typography-level-from-existing-vars(
      'fmnts-form-error',
      'fmnts-text-sm'
    );
  }
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
