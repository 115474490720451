@use '../../../core/theming/theming';

@mixin base($theme) {
  /** ===================================
  ** Dropdown Size
  ** ===================================*/
  .fmnts-select {
    --fmnts-select--visible-options: 7;
    --fmnts-select-option--min-height: 2.5rem;
    --fmnts-select-dropdown--max-height: calc(
      var(--fmnts-select-option--min-height) *
        var(--fmnts-select--visible-options)
    );
  }

  .fmnts-select {
    &--lg {
      --fmnts-select-option--min-height: 3rem;
    }

    &--sm {
      --fmnts-select-option--min-height: 2rem;
    }
  }
}

@mixin color($theme) {
  /** ===================================
  ** Colors General
  ** ===================================*/
  .fmnts-select {
    // Variables for label
    --fmnts-select-label--color: var(--fmnts-color--white-contrast);
    // Variables for dropdown
    --fmnts-select-dropdown--bg-color: var(--fmnts-color--white);
    --fmnts-select-dropdown--border-color: var(--fmnts-color--white-darkest);
    --fmnts-select-dropdown--box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
      rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    --fmnts-select-option-divider--color: var(--fmnts-color--white-darkest);
    --fmnts-select-option-none--color: var(--fmnts-color--gray-dark);
  }
}
@mixin typography($theme) {
  $select--sm--font-size: 1rem;
  $select--sm--font-weight: 400;
  $select--md--font-size: 1rem;
  $select--md--font-weight: 400;
  $select--lg--font-size: 1.25rem;
  $select--lg--font-weight: 400;

  /** ===================================
  ** Variants for size
  ** ===================================*/
  .fmnts-select {
    --fmnts-select--font-size: #{$select--md--font-size};
    --fmnts-select--font-weight: #{$select--md--font-weight};
  }

  .fmnts-select--sm {
    --fmnts-select--font-size: #{$select--sm--font-size};
    --fmnts-select--font-weight: #{$select--sm--font-weight};
  }

  .fmnts-select--lg {
    --fmnts-select--font-size: #{$select--lg--font-size};
    --fmnts-select--font-weight: #{$select--lg--font-weight};
  }
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
