@use 'sass:map';
@use '../../../core/theming/theming';

@mixin base($theme) {
}

@mixin color($theme) {
  $icon-rating-vars: theming.get-component-vars($theme, 'icon-rating');

  $rating-icon-color-unfilled: map.get($icon-rating-vars, 'color-unfilled');
  $rating-icon-color-filled: map.get($icon-rating-vars, 'color-filled');

  .fmnts-icon-rating {
    --fmnts-icon-rating-icon--unfilled--color: #{$rating-icon-color-unfilled};
    --fmnts-icon-rating-icon--filled--color: #{$rating-icon-color-filled};
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
