@use '../mixins/shadow.mixins' as shadow;

/// Generates CSS variables for theme border styles.
@mixin css-vars-from-theme($theme) {
  @include -apply-common-border-radius-vars($theme);
}

/// CSS variables for border radius.
@mixin -apply-common-border-radius-vars($theme) {
  --fmnts-border-radius--none: 0;
  --fmnts-border-radius--full: 9999px;
  --fmnts-border-radius--xs: 0.125rem;
  --fmnts-border-radius--sm: 0.25rem;
  --fmnts-border-radius--md: 0.375rem;
  --fmnts-border-radius--lg: 0.5rem;
  --fmnts-border-radius--xl: 0.75rem;
  --fmnts-border-radius--2xl: 1rem;
}
