@use 'sass:map';
@use '../mixins/color.mixins' as color-utils;
@use '../mixins/sass-utils.mixins' as utils;
@use './theming';

/// Mixin that applies the passed content either
/// in the current selector or in :root
@mixin -apply-in-root-or-context() {
  @include utils.current-selector-or-root(':root') {
    @content;
  }
}

@mixin -apply-breakpoint-vars($theme) {
  $breakpoints: map.get($theme, 'breakpoints');

  @each $breakpoint, $size in $breakpoints {
    --fmnts-breakpoint--#{$breakpoint}: #{$size};
  }
}

@mixin -apply-common-spacing-vars($theme) {
  --fmnts-space-0: 0rem;
  --fmnts-space-025: 0.125rem;
  --fmnts-space-050: 0.25rem;
  --fmnts-space-075: 0.375rem;
  --fmnts-space-100: 0.5rem;
  --fmnts-space-150: 0.75rem;
  --fmnts-space-200: 1rem;
  --fmnts-space-250: 1.25rem;
  --fmnts-space-300: 1.5rem;
  --fmnts-space-400: 2rem;
  --fmnts-space-500: 2.5rem;
  --fmnts-space-600: 3rem;
  --fmnts-space-800: 4rem;
  --fmnts-space-1000: 5rem;
}

@mixin -apply-common-input-vars($theme) {
  // State: default
  --fmnts-input--bg-color: var(--fmnts-color--white);
  --fmnts-input--border-color: var(--fmnts-color--white-darker);
  --fmnts-input--border-style: solid;
  --fmnts-input--border-width: 1px;
  --fmnts-input--border-radius: 0.25rem;
  --fmnts-input--color: inherit;
  --fmnts-input--font-weight: normal;
  --fmnts-input--font-size: 1rem;
  --fmnts-input--line-height: 1.5;
  --fmnts-input--padding-top: 1rem;
  --fmnts-input--padding-bottom: 0.75rem;
  --fmnts-input--padding-start: 0.75rem;
  --fmnts-input--padding-end: 0.75rem;
  --fmnts-input--padding: var(--fmnts-input--padding-top)
    var(--fmnts-input--padding-end) var(--fmnts-input--padding-bottom)
    var(--fmnts-input--padding-start);
  --fmnts-input-placeholder--color: var(--fmnts-color--gray-darker);

  --fmnts-input--transition-duration: var(--fmnts-duration--short-3);
  --fmnts-input--transition-timing-function: var(--fmnts-ease--emphasized);
  --fmnts-input--transition: border-color
      var(--fmnts-input--transition-duration)
      var(--fmnts-input--transition-timing-function),
    box-shadow var(--fmnts-input--transition-duration)
      var(--fmnts-input--transition-timing-function);

  // State: disabled
  --fmnts-input--disabled--bg-color: var(--fmnts-color--white-dark);
  --fmnts-input--disabled--border-color: var(--fmnts-color--white-darker);

  // State: focus
  --fmnts-input--focus--border-color: var(--fmnts-color--primary);

  // State: invalid
  --fmnts-input--invalid--border-color: var(--fmnts-color--danger);
}

/// Applies custom css props for divders
@mixin -apply-common-divider-vars($theme) {
  --fmnts-divider--width: 1px;
  --fmnts-divider--style: solid;
  --fmnts-divider--color: var(--fmnts-color--white-darkest);

  --fmnts-divider--subtle--color: #{theming.color-rgb(
      $variant: 'black',
      $hue: 'default',
      $alpha: 0.15
    )};

  // Combine "default" variant to a single variable to use in borders
  --fmnts-divider--border: var(--fmnts-divider--width)
    var(--fmnts-divider--style) var(--fmnts-divider--color);
  // Combine "subtle" variant to a single variable to use in borders
  --fmnts-divider--subtle--border: var(
      --fmnts-divider--subtle--width,
      var(--fmnts-divider--width)
    )
    var(--fmnts-divider--subtle--style, var(--fmnts-divider--style))
    var(--fmnts-divider--subtle--color, var(--fmnts-divider--color));
}

@mixin apply-base($theme) {
  @include -apply-in-root-or-context {
    @include -apply-breakpoint-vars($theme);
    @include theming.animation-css-vars-from-theme($theme);
    @include theming.border-css-vars-from-theme($theme);
    @include theming.elevation-css-vars-from-theme($theme);
    @include theming.native-html-common-css-vars($theme);
    @include theming.typography-common-css-vars();
    @include -apply-common-input-vars($theme);
    @include -apply-common-spacing-vars($theme);
    @include -apply-common-divider-vars($theme);
  }
}

@mixin apply-color($theme) {
  @include -apply-in-root-or-context {
    @each $variant in theming.$color-contextual-variants {
      @include theming.color-css-vars-from-theme($theme, $variant);
    }

    // Also add general variants
    @include theming.color-css-vars-from-theme($theme, 'black');
    @include theming.color-css-vars-from-theme($theme, 'white');
    @include theming.color-css-vars-from-theme($theme, 'gray');
  }
}

@mixin apply-typography($theme) {
  @include -apply-in-root-or-context {
    @include theming.typography-css-vars-from-theme($theme);
  }
}

@mixin apply-theme($theme) {
  @include apply-base($theme);
  @include apply-color($theme);
  @include apply-typography($theme);
}
