@use 'sass:color';
@use 'sass:map';
@use '../mixins/color.mixins' as color-utils;

/// just an internal we keep for prefixing our coloring scheme
$-css-var-prefix: --fmnts-color;

/// List of all contextual color variants for theming
$contextual-variants: 'primary', 'danger', 'success', 'warn' !default;

/// List of known color hues
$hues: (
  'default',
  'dark',
  'darker',
  'darkest',
  'light',
  'lighter',
  'lightest'
) !default;

/// Creates modifier CSS classes for each variant
/// in $variants-names and applies the given
/// variables in $css-vars with the specified hue
@mixin variant-classes-with-css-vars(
  // Prefix of the CSS class that should be created
  $element,
  // Map of CSS variables that map to a hue
  $css-vars,
  // List of variant names
  $variants-names: $contextual-variants
) {
  @each $variant-name in $variants-names {
    $selector: '#{$element}--#{$variant-name}';
    &.#{$selector} {
      @each $css-var, $hue in $css-vars {
        $var-name: -css-var-name($variant-name, $hue);
        #{$css-var}: var(#{$var-name});
      }
    }
  }
}

/// Creates a color variant based on a theme color.
@function rgb($variant, $hue, $alpha) {
  $var-name: -css-var-name($variant, $hue, 'rgb');

  @return color-utils.rgb(var(#{$var-name}), $alpha);
}

/// Creates variables for colors for the passed theme and variant
/// Use $variant-name to rename the css variable
@mixin css-vars-from-theme($theme, $variant, $variant-name: '') {
  // When no explicit variant name was given, use the name from variant
  @if $variant-name == '' {
    $variant-name: $variant;
  }

  @each $hue in $hues {
    // First colors
    $color: from-theme-by-variant($theme, $variant, $hue);
    $var-name: -css-var-name($variant-name, $hue);
    #{$var-name}: #{$color};
    #{$var-name}-rgb: color-utils.for-css-rgb-function($color);
    #{$var-name}-hsl: color-utils.for-css-hsl-function($color);

    // Then contrast colors
    $contrast-color: from-theme-by-contrast-variant($theme, $variant, $hue);
    $contrast-var-name: -css-var-name($variant-name, $hue, 'contrast');
    #{$contrast-var-name}: #{$contrast-color};
    #{$contrast-var-name}-rgb: color-utils.for-css-rgb-function(
      $contrast-color
    );
    #{$contrast-var-name}-hsl: color-utils.for-css-hsl-function(
      $contrast-color
    );
  }
}

/// Returns the given $hue for the given color $variant
/// from the $theme object.
@function from-theme-by-variant($theme, $variant, $hue: 'default') {
  $theme-colors: map.get($theme, 'colors');
  $color-variant: map.get($theme-colors, $variant);

  @return map.get($color-variant, $hue);
}

/// Returns the contrast color for the given $hue for the
/// given color $variant from the $theme object.
@function from-theme-by-contrast-variant($theme, $variant, $hue: 'default') {
  @return from-theme-by-variant($theme, $variant, 'contrast-#{$hue}');
}

/// Returns the name for a color variable
@function -css-var-name($variant, $hue, $suffix: '') {
  $hue-suffix: -#{$hue};
  @if $hue == 'default' {
    $hue-suffix: '';
  }

  $suffix-combined: #{$hue-suffix}-#{$suffix};
  @if $suffix == '' {
    $suffix-combined: #{$hue-suffix};
  }

  @return #{$-css-var-prefix}--#{$variant}#{$suffix-combined};
}
