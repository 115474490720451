@use '../../../core/theming/theming' as theming;

@mixin -fmnts-form-hint-vars() {
  .fmnts-form-hint {
    --fmnts-form-hint--color: var(--fmnts-label--color);
  }
}

@mixin -fmnts-form-hint-styles() {
  .fmnts-form-hint {
    display: block;
    color: var(--fmnts-form-hint--color);
    @include theming.typography-from-css-vars('fmnts-form-hint');
    // Usually set within fmnts-form-field
    padding: var(--fmnts-form-hint--padding);
  }
}

@mixin base($theme) {
  @include -fmnts-form-hint-vars();
  @include -fmnts-form-hint-styles();
}

@mixin color($theme) {
}

@mixin typography($theme) {
  .fmnts-form-hint {
    @include theming.typography-level-from-existing-vars(
      'fmnts-form-hint',
      'fmnts-text-sm'
    );
  }
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
