@use 'sass:map';

// Include content under the current selector (&) or the document root if there is no current
// selector.
@mixin current-selector-or-root($root: html) {
  @at-root #{& or $root} {
    @content;
  }
}

// Include content under the current selector (&) or the document root if there is no current
// selector.
@mixin current-selector-or-root-scope() {
  @if & {
    @at-root #{&} {
      @content;
    }
  } @else {
    @at-root :root {
      @content;
    }
  }
}

/// Filters all key value pairs from a map
/// that have a given value
/// @param {unknown} $value Value to filter
/// @param {Map} $map Map to filter
@function filter-value-from-map($filterValue, $map) {
  $result: ();

  @each $key, $value in $map {
    @if $value != $filterValue {
      $result: map.set($result, $key, $value);
    }
  }

  @return $result;
}
