@use '../../../core/mixins/input.mixins' as input-field;
@use '../../../core/theming/theming' as theming;

@mixin -fmnts-input() {
  .fmnts-input {
    height: initial;
    width: 100%;
    appearance: none;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;

    @include theming.typography-from-css-vars('fmnts-input');

    &:focus {
      @include input-field.input-focus-style-from-css-vars();
      outline: 0;
    }

    &::placeholder {
      @include input-field.placeholder-style-from-css-vars();
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    &.ng-touched.ng-invalid {
      @include input-field.input-invalid-style-from-css-vars();
    }

    &:disabled,
    &.disabled,
    &[disabled],
    &[readonly] {
      @include input-field.input-disabled-style-from-css-vars();
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }
  }

  .fmnts-input-form-field-textarea-control {
    vertical-align: middle;
    resize: vertical;
    box-sizing: border-box;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    overflow: auto;
  }
}

@mixin base($theme) {
  @include -fmnts-input();

  .fmnts-input {
  }
}

@mixin color($theme) {
  .fmnts-input {
  }
}

@mixin typography($theme) {
  .fmnts-input {
  }
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
