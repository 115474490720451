@use '../../core/theming/theming';
@use '../../core/theming/variables';

@mixin base($theme) {
  fmnts-breadcrumbs {
    // Set custom size for Icon/Avatar/Rocket
    --fmnts-breadcrumb--visual-size: 1.875rem;
  }
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
