@mixin common-css-vars($theme) {
  --fmnts-link--color: var(--fmnts-color--primary);
  --fmnts-link--hover--color: var(--fmnts-color--primary-darkest);
}

@mixin links() {
  $default-color: var(--fmnts-link--color);
  $default-text-decoration: var(--fmnts-native-a--text-decoration, underline);

  a {
    color: var(--_fmnts-native-a--color);
    text-decoration: var(--_fmnts-native-a--text-decoration);

    &:link {
      --_fmnts-native-a--color: #{$default-color};
      --_fmnts-native-a--text-decoration: #{$default-text-decoration};
    }

    &:visited {
      --_fmnts-native-a--visited--color: var(
        --fmnts-native-a--visited--color,
        #{$default-color}
      );
      --_fmnts-native-a--visited--text-decoration: var(
        --fmnts-native-a--visited--text-decoration,
        #{$default-text-decoration}
      );
    }

    &:link:hover {
      --_fmnts-native-a--color: var(
        --fmnts-native-a--hover--color,
        var(--fmnts-color--primary-darker)
      );
      --_fmnts-native-a--text-decoration: var(
        --fmnts-native-a--hover--text-decoration,
        #{$default-text-decoration}
      );
    }

    &:active {
      --_fmnts-native-a--color: var(
        --fmnts-native-a--active--color,
        #{$default-color}
      );
      --_fmnts-native-a--text-decoration: var(
        --fmnts-native-a--active--text-decoration,
        #{$default-text-decoration}
      );
    }
  }
}
