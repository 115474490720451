@use './box-alignment.mixins' as box;

// Mixins for flex-box

/**
 * Generates defined modifiers for `flex-direction` property
 * on flex-box containers
 */
@mixin generate-flex-direction-modifiers {
  &--row {
    flex-direction: row;
  }
  &--row-reverse {
    flex-direction: row-reverse;
  }
  &--column {
    flex-direction: column;
  }
  &--column-reverse {
    flex-direction: column-reverse;
  }
}

/**
 * Generates defined modifiers for `flex-wrap` property
 * on flex-box containers
 */
@mixin generate-flex-wrap-modifiers {
  &--wrap {
    flex-wrap: wrap;
  }
  &--wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  &--nowrap {
    flex-wrap: nowrap;
  }
}

/**
 * Generates defined modifiers for `align-self`
 * on flex-box items
 */
@mixin generate-flex-align-self-modifiers() {
  &--align-self {
    &-start {
      align-self: flex-start;
    }
    &-end {
      align-self: flex-end;
    }
    &-center {
      align-self: center;
    }
    &-baseline {
      align-self: baseline;
    }
  }
}

// Adds a gap between flex items along the main axis
// Not all browsers support the "gap" property yet.
@mixin item-gap(
  $gap,
  // Should be 'row' or 'column'.
  $direction,
  // Selector for which the gap should be applied.
  $item-selector: '*',
  // Whether the flex direction is reversed or not.
  $reversed: false,
  // Whether the flex container wraps items or not
  // Since there is no way via CSS to know if a item
  // is wrapped, enabling this applies the gap to all
  // elements for best visual consistency.
  $wrapped: false
) {
  $space-prop-start: 'margin-left';
  $space-prop-end: 'margin-right';
  @if $direction == 'column' {
    $space-prop-start: 'margin-top';
    $space-prop-end: 'margin-bottom';
  }

  > #{$item-selector} {
    --fmnts-flex-item--gap: #{$gap};
    #{$space-prop-start}: calc(0.5 * var(--fmnts-flex-item--gap));
    #{$space-prop-end}: calc(0.5 * var(--fmnts-flex-item--gap));

    @if $wrapped == false {
      $child-head: 'first-child';
      $child-tail: 'last-child';
      @if $reversed == true {
        $child-head: 'last-child';
        $child-tail: 'first-child';
      }

      &:#{$child-head} {
        #{$space-prop-start}: 0;
      }
      &:#{$child-tail} {
        #{$space-prop-end}: 0;
      }
    }
  }
}

/**
 * Modifiers to be applied on a flex container.
 */
@mixin generate-container-modifieres() {
  @include generate-flex-direction-modifiers();
  @include generate-flex-wrap-modifiers();
  @include box.inline-axis-content-modifiers();
  @include box.block-axis-item-modifiers();
}

/**
 * Modifiers to be applied on a flex item.
 */
@mixin generate-item-modifiers() {
  @include generate-flex-align-self-modifiers();
}
