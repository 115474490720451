@use '../../core/theming/theming';
@use '../../core/mixins/sass-utils.mixins' as utils;

@mixin -styles() {
  // Common typography
  .fmnts-body-lg {
    p {
      margin-top: var(--fmnts-paragraph-lg--margin-top);
      margin-bottom: var(--fmnts-paragraph-lg--margin-bottom);
    }
  }

  .fmnts-body-md {
    p {
      margin-top: var(--fmnts-paragraph-md--margin-top);
      margin-bottom: var(--fmnts-paragraph-md--margin-bottom);
    }
  }

  .fmnts-body-sm {
    p {
      margin-top: var(--fmnts-paragraph-sm--margin-top);
      margin-bottom: var(--fmnts-paragraph-sm--margin-bottom);
    }
  }

  // Native HTML headers
  .fmnts-h1,
  .fmnts-h2,
  .fmnts-h3,
  .fmnts-h4,
  .fmnts-h5,
  .fmnts-h6 {
    margin-top: var(--fmnts-headline--margin-top);
    margin-bottom: var(--fmnts-headline--margin-bottom);
  }
}

@mixin base($theme) {
  @include -styles();
}

@mixin color($theme) {
}

@mixin typography($theme) {
  @each $variant in theming.$typography-text-size-variants {
    .fmnts-text-#{$variant} {
      @include theming.typography-from-css-vars(fmnts-text-#{$variant});
    }
  }

  .fmnts-body-lg {
    @include theming.typography-from-css-vars(fmnts-body-lg);
  }
  .fmnts-body-md {
    @include theming.typography-from-css-vars(fmnts-body-md);
  }
  .fmnts-body-sm {
    @include theming.typography-from-css-vars(fmnts-body-sm);
  }
  .fmnts-small,
  .fmnts-caption-md {
    @include theming.typography-from-css-vars(fmnts-caption-md);
  }

  // Form elements
  .fmnts-label-md {
    @include theming.typography-from-css-vars(fmnts-label-md);
  }
  .fmnts-label-sm {
    @include theming.typography-from-css-vars(fmnts-label-sm);
  }

  // Native HTML headers
  .fmnts-h1,
  .fmnts-headline-1 {
    @include theming.typography-from-css-vars(fmnts-headline-1);
  }
  .fmnts-h2,
  .fmnts-headline-2 {
    @include theming.typography-from-css-vars(fmnts-headline-2);
  }
  .fmnts-h3,
  .fmnts-headline-3 {
    @include theming.typography-from-css-vars(fmnts-headline-3);
  }
  .fmnts-h4,
  .fmnts-headline-4 {
    @include theming.typography-from-css-vars(fmnts-headline-4);
  }
  .fmnts-h5,
  .fmnts-headline-5 {
    @include theming.typography-from-css-vars(fmnts-headline-5);
  }
  .fmnts-h6,
  .fmnts-headline-6 {
    @include theming.typography-from-css-vars(fmnts-headline-6);
  }

  // Display variants
  .fmnts-display-lg {
    @include theming.typography-from-css-vars(fmnts-display-lg);
  }
  .fmnts-display-md {
    @include theming.typography-from-css-vars(fmnts-display-md);
  }
  .fmnts-display-sm {
    @include theming.typography-from-css-vars(fmnts-display-sm);
  }

  // Title variants
  .fmnts-title-md {
    @include theming.typography-from-css-vars(fmnts-title-md);
  }
  .fmnts-subtitle-md {
    @include theming.typography-from-css-vars(fmnts-subtitle-md);
  }
  .fmnts-overline-md {
    @include theming.typography-from-css-vars(fmnts-overline-md);
  }
  .fmnts-footer-md {
    @include theming.typography-from-css-vars(fmnts-footer-md);
  }
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
