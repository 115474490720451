@use '../../../core/mixins/breakpoints.mixins' as bp;
@use '../../../core/mixins/layout.mixins' as layout;

@mixin base($theme) {
  .fmnts-grid {
    display: grid;
  }

  .fmnts-grid--auto-flow {
    grid-auto-flow: var(--fmnts-grid--auto-flow);
  }

  .fmnts-grid--gap {
    gap: var(--fmnts-grid--gap);
  }

  .fmnts-grid--template-columns {
    grid-template-columns: var(--fmnts-grid--template-columns);
  }

  .fmnts-grid--template-rows {
    grid-template-rows: var(--fmnts-grid--template-rows);
  }

  .fmnts-grid--auto-grid {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(var(--fmnts-grid--auto-grid-min-width), 100%), 1fr)
    );
  }
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
