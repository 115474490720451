@mixin base($theme) {
  fmnts-sidebar {
    --fmnts-sidebar--box-shadow: 0 8px 10px -5px #0003,
      0 16px 24px 2px #00000024, 0 6px 30px 5px #0000001f;
    --fmnts-sidebar--border-radius: 0.75rem;
    --fmnts-sidebar--min-height: 100%;
    --fmnts-sidebar--max-height: 100vh;
    --fmnts-sidebar--min-width: 200px;
    --fmnts-sidebar--max-width: 360px;
  }
}

@mixin color($theme) {
  fmnts-sidebar {
    --fmnts-sidebar--color: var(--fmnts-color--white-contrast);
    --fmnts-sidebar--bg-color: var(--fmnts-color--white);
    --fmnts-sidebar--scroll-color: var(--fmnts-color--primary);
    --fmtns-sidebar--border-color: var(--fmnts-color--white-darker);
  }
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
